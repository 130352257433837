<template>
    <standard-page :definition="$DEFINITIONS.newDid" title="New Decentralized Identifier">
        <template v-slot:breadcrumb>

      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'identities-catalogs-dids'}" v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</router-link>
      </span>
            <span>New</span>
        </template>
        <template v-if="properties.length === 0" v-slot:buttons>
            <button @click="addProperty()" class="d-none d-xl-block btn float-right btn-orange rounded font-weight-bold" title="Add Catalog">
                <i class="fas fa-plus-square mg-r-10"/>Add Property
            </button>
            <button @click="addProperty()" class="d-xl-none w-35 btn btn-icon float-right btn-orange rounded font-weight-bold" title="Add Catalog">
                <i class="typcn typcn-document-add"/>
            </button>
        </template>
        <template v-slot:content>
            <div class="tab-pane-body">
                <div class="row row-sm mg-t-20 mg-b-20">
                    <div class="col-lg-3 ">
                        <input :class="{'is-invalid': submitted && errors.has('name')}" class="form-control rounded" data-vv-as="DID name" id="name" name="name" placeholder="DID name" type="text"
                               v-model="name" v-validate="'required'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                    </div>
                </div>
                <div class="pd-30 pd-sm-40 bg-gray-100" v-if="properties.length>0">
                    <div class="row row-xs align-items-center mg-b-20" v-for="(property, index) in properties">
                        <div class="col-md-1 d-none d-xl-block">
                            <label class="form-label mg-b-0">{{index+1}}.</label>
                        </div>
                        <div class="col-md-3 mg-t-5 mg-md-t-0">
                            <input class="form-control rounded" id="did_property" name="did_property" placeholder="Enter Key" required type="text" v-model="property.name">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0">
                            <select aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" tabindex="-1" v-model="property.data_type">
                                <option v-bind:value=null>--- Select a type ---</option>
                                <option v-bind:key="index" v-for="(type, index) in data_types">{{type}}</option>
                            </select>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.data_type === 'Text' && property.options && property.options.length > 0">
                            <select :data-vv-as="property.name" :id="'attribute_value_sm1'+index" :name="'attribute_value_sm1'+index" class="form-control custom-select"
                                    v-if="property.options && property.options.length >0" v-model="property.value">
                                <option :key="val" :value="val" v-for="val in property.options">
                                    {{val}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-else-if="property.data_type === 'Text' || property.data_type === 'Location'">
                            <input class="form-control rounded" id="property_string_value" name="property_string_value" placeholder="Enter value" required type="text" v-model="property.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.data_type === 'Number' || property.data_type === 'Float'">
                            <input class="form-control rounded" id="property_u8_value" name="property_u8_value" placeholder="Enter value" required type="number" v-model="property.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.data_type === 'Date'">
                            <input class="form-control rounded" data-vv-as="Expiry" id="property_date_value" name="property_date_value" type="date" v-model="property.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.data_type === 'Yes/No'">
                            <label class="rdiobox">
                                <input :name="index+'_yesno'" type="radio" v-bind:value=true v-model="property.value">
                                <span>Yes</span>
                            </label>
                            <label class="rdiobox">
                                <input :name="index+'_yesno'" type="radio" v-bind:value=false v-model="property.value">
                                <span>No</span>
                            </label>
                        </div>
                        <div class="custom-file col-md-2 mg-t-5 mg-md-t-0" v-if="property.data_type === 'File'">
                            <input :id="index" :name="property.name" @change="fileChange" class="custom-file-input" type="file">
                            <label :for="index" class="custom-file-label">{{property.file_name}}</label>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0">
                            <div class="form-check form-check-inline">
                                <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio"
                                       v-model="property.private" v-validate="'required'">
                                <label class="form-check-label" for="Yes">Private</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio"
                                       v-model="property.private">
                                <label class="form-check-label" for="No">Public</label>
                            </div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <a @click="removeProperty(index)" class="btn btn-block btn-danger rounded" href="javascript:void(0)"><i class="fa fa-trash-alt"></i></a>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <a @click="addProperty" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" v-if="index+1 === properties.length"><i
                                    class="fa fa-plus-square"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-sm mg-t-20">
                <div class="col-lg-2 mg-t-5 offset-lg-8">
                    <button @click="addProperties" class="btn btn-primary w-100 rounded font-weight-bold" type="button"> Submit
                    </button>
                </div>
                <div class="col-lg-2 mg-t-5">
                    <button @click="reset" class="btn btn-secondary w-100 rounded font-weight-bold" type="button"> Reset
                    </button>
                </div>
            </div>
        </template>
    </standard-page>

</template>

<script>
    import EventBus from "@/event-bus";

    export default {
        name: "NewDidWithProperty",
        props: ['catalogid', 'catalogNameProp'],
        data() {
            return {
                file: File,
                catalogName: this.catalogNameProp,
                submitted: false,
                name: null,
                properties: [
                    {
                        name: null,
                        private: true,
                        value: null,
                        data_type: 'Text',
                        is_null: true,
                        file_name: 'Upload'
                    }
                ],
                request_properties: [],
                data_types: ['Text', 'Number', 'Float', 'Yes/No', 'Date', 'File', 'Location'],
                selected_data_type: null,
                did_part: null,
                did_template: null,
                did_attachments: []
            };
        },
        mounted() {
            // AmazeUI Datetimepicker
            $('#datetimepicker').datetimepicker({
                format: 'yyyy-mm-dd hh:ii',
                autoclose: true
            });
            this.init();
        },
        methods: {
            async init() {
                if (!this.catalogName) {
                    await this.getCatalog();
                }
                await this.getActiveDidTemplate();
                await this.getDidTemplateProperties()
            },
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            async getActiveDidTemplate() {
                try {
                    let {data} = await this.$identity.get(`/identity/catalogs/${this.catalogid}/active_did_template`);
                    this.did_template = data.template ? data.template.template : null;
                } catch (e) {
                }
            },
            async getDidTemplateProperties() {
                if (this.did_template) {
                    try {
                        let {data} = await this.$identity.get(`/identity/catalogs/${this.catalogid}/templates/${this.did_template}`);
                        this.properties = data.map(property => ({
                            ...property,
                            data_type: property.fact_type === 'Integer' ? 'Number' : property.fact_type === 'Bool' ? 'Yes/No' : property.fact_type,
                            options: property.options && typeof property.options === 'string' ? property.options.split(';') : [],
                            file_name: 'Upload'
                        }));
                    } catch (e) {
                        this.$toastr.e("Error getting catalog name", "Error");
                    }
                }
            },
            async fileChange(e) {
                this.did_attachments = this.did_attachments.filter(a => a.name !== e.target.name)
                let file = e.target.files[0];
                this.properties[e.target.id].file_name = file.name;
                this.did_attachments.push({
                    name: e.target.name,
                    file: file
                });
            },
            async addProperty() {
                this.properties.push({
                    name: null,
                    private: true,
                    value: null,
                    data_type: 'Text',
                    is_null: true
                });
            },
            getDatatype(type, val) {
                switch (type) {
                    case "Text":
                        return 'String';
                    case "Float":
                        return 'String';
                    case "Number":
                        if (val >= 0 && val < 256) {
                            return "U8";
                        } else if (val > 255 && val < 65537) {
                            return "U16";
                        } else if (val > 65536 && val < 4000001) {
                            return "U32";
                        } else if (val) {
                            return "U128";
                        }
                    case "Yes/No":
                        return "Bool";
                    case "Location":
                        return "String";
                    default:
                        return type;
                }
            },
            removeProperty(index) {
                this.properties.splice(index, 1);
            },
            async addProperties() {
                this.request_properties = [];
                await this.arrangeProperties();

                try {
                    EventBus.$emit('openLoader');

                    this.submitted = true;
                    let valid = await this.$validator.validate();

                    if (valid) {
                        let {data} = await this.$identity.post(`/identity/controller/dids`, {
                            catalog_id: parseInt(this.catalogid),
                            properties: this.request_properties,
                            short_name: this.name,
                        });
                        await this.attachDidDocs(data.id);
                        this.$toastr.s(this.$DEFINITIONS.identity.toasts.createDid, 'Success');
                        await this.$router.push({name: 'identities-catalogs-dids'});
                    }
                } catch (e) {
                    this.$toastr.e("Did upload failed", "Error");
                    console.log(e);
                } finally {
                    EventBus.$emit('closeLoader');
                }
            },
            async attachDidDocs(did) {
                for (let i = 0; i < this.did_attachments.length; i++) {
                    let attachment = this.did_attachments[i];
                    let f = new FormData();
                    f.append('file', attachment.file);
                    await this.$identity.post(`identity/dids/${did}/documents?property_name=${attachment.name}`, f, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'BWS ' + localStorage.getItem("token")
                        }
                    })
                }
            },
            async arrangeProperties() {
                this.properties.forEach(property => {
                    if (property.name && property.value && property.data_type) {
                        let data_type = this.getDatatype(property.data_type, property.value);
                        let req_obj = {
                            name: property.name,
                            type: data_type,
                            private: property.private,
                            value: property.value.toString()
                        };
                        this.request_properties.push(req_obj);
                    }
                });
            },
            reset() {
                this.properties = [];
            }
        }
    }
</script>

<style scoped>
    .custom-inactive {
        pointer-events: none;

        /* for "disabled" effect */
        opacity: 0.5;
        background: #ccc;
    }
</style>